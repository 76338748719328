/* src/fonts.css */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/TitilliumWeb-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/TitilliumWeb-Bold.ttf') format('truetype');
  }
  